<template>
    <div class="about">
        <van-nav-bar :title="$t('Mt4ApiMenu.aboutUs')" :left-text="$t('toback')" left-arrow
                     @click-left="back"/>
        <div class="page member">

            <div class="cblock">
                <van-cell-group>
                    <van-cell  center>
                        <van-col span="24" class="user-info-text">
                            
                            <div class="description">
                                <span>&copy; Copyright 2021 - HIPPO POWER LIMITED</span>
                            </div>
                        </van-col>
                    </van-cell>
                </van-cell-group>                
            </div>           
        </div>




    </div>
</template>
<script>
    export default {
        data() {
            return {
                

            };
        },
        computed: {},
        mounted() {
            this.$store.commit("toggleTabbarShow", false);
            this.$store.commit("toggleTabbarCurrentShow", "Mt4");
            
        },
        created() {

        },
        beforeDestroy() { },
        methods: {
            back() {
                
                this.$router.push({
                    path: '/settings',
                    
                })
            },
            
            
        },
    };
</script>


<style scoped>
    #app {
        background-color: #f7f7f7;
    }

    


    .page {
        margin-top: 0.3rem;
        margin-bottom: 1rem;
    }

    .cblock {
        margin: 0 10px;
    }



    .margin-top {
        margin-top: 0.5rem;
    }

    .cblock:last-child {
        padding-bottom: 0.5rem;
    }
    .description {font-size:12px;
    }
    .title{font-size:16px;}
    
</style>
<style>
    .about .van-nav-bar {
        background-color: #13b5b1;
    }

    .about .van-nav-bar__title {
        color: #fff;
    }

    .about .van-nav-bar .van-icon, .about .van-nav-bar__text {
        color: #fff;
    }
</style>
